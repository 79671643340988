import { forwardRef, ReactElement, ReactNode } from "react";
import { Button, ButtonProps, styled, useTheme } from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";


export interface CabButtonProps extends CabComponentProps<ButtonProps> {
  children?: ButtonProps['children'];
  buttonType?: 'primary'|'secondary'|'tertiary'|'text';
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  disabled?: ButtonProps['disabled'];
  id?: ButtonProps['id'];
  icon?: ReactNode;
  onClick?: ButtonProps['onClick']
  type?: ButtonProps['type']
  startIcon?: ButtonProps['startIcon']
  endIcon?: ButtonProps['endIcon']
  HTML5DataAttributes?:{[attribute: string]: string}
  onMouseEnter?: ButtonProps["onMouseEnter"]
  onMouseLeave?: ButtonProps["onMouseLeave"]
}

export const CabButton = forwardRef<HTMLButtonElement, CabButtonProps>(({
  children, buttonType, color, disabled, size, icon, endIcon, overrides, sx, className, onClick, type, id, 
  HTML5DataAttributes, onMouseEnter, onMouseLeave
}, ref): ReactElement => {
  const theme = useTheme();

  const buttonProps: ButtonProps = {
    size,
    disabled,
    startIcon: icon,
    endIcon,
    id,
    onClick,
    onMouseEnter,
    onMouseLeave
  };

  switch (buttonType) {
    case 'secondary':
      buttonProps.variant = 'outlined';
      buttonProps.color = 'secondary';
      buttonProps.sx = { color: theme.palette.mode === 'dark' ? colors.white900 : colors.ink };
      break;

    case 'tertiary':
      buttonProps.variant = 'outlined';
      buttonProps.color = 'primary';
      // buttonProps.sx = { color: theme.palette.mode === 'dark' ? undefined : colors.lavendar900 };
      break;

    case 'text':
      buttonProps.variant = 'text';
      buttonProps.sx = { color: theme.palette.mode === 'dark' ? undefined : colors.ink };
      break;
  
    default: // primary
      buttonProps.variant = 'contained';
      buttonProps.color = 'primary';
      break;
  }

  if (color) { // allow color to override buttonType set colors
    buttonProps.color = color;
  }

  if (sx) {
    buttonProps.sx = [
      ...(Array.isArray(buttonProps.sx) ? buttonProps.sx : [buttonProps.sx]),
      ...(Array.isArray(sx) ? sx : [sx])
    ];
  }

  return (
    <ButtonStyled ref={ref} type={type} {...buttonProps} {...overrides} {...HTML5DataAttributes} className={className}>
      <span className="button-text">
        {children}
      </span>
    </ButtonStyled>
  );
});

const ButtonStyled = styled(
  Button, { label: "ButtonStyled" }
)<CabButtonProps>(({ theme, color, variant, size, disabled, onMouseEnter, onMouseLeave }) => {
  return {
    ...(size === 'small' && {
      height: 32,
      fontSize: 14,
      fontWeight: "normal"
    }),
    ...(theme.palette.mode === 'dark' ? {} : {
      // '&:hover': {
      //   backgroundColor: color && color !== 'inherit' && theme.palette[color]
      //     ? lighten(theme.palette[color].main, variant === 'text' || variant === 'outlined' ? 0.9 : 0.1)
      //     : undefined,
      // },
      ...(variant === "text" && {
        '& svg': {
          color: color && color !== 'inherit' && theme.palette[color] && !disabled
            ? theme.palette[color].main 
            : disabled ? colors.black300 : theme.palette.accent.main
        }
      }),
      // ...(color === "secondary" && {
      //   borderColor: theme.palette.secondary.main,
      // }),
    }),
    textTransform: 'none',
  };
});