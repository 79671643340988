import { FC, memo, ReactElement, useEffect, useState } from "react";
import { FormControl, FormControlProps, styled, Typography } from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import { CabAutocomplete, CabAutocompleteProps } from "@CabComponents/CabAutocomplete";
import colors from "../../../colors";
import { MEETING_DURATION_HOURS, MEETING_DURATION_MINUTES } from "../../../constants";

export interface ICabDurationPicker extends CabComponentProps<FormControlProps>  {
  sx?: FormControlProps['sx'];
  durationMinutes: number;
  size?: "small" | "medium"
  onChange: (newDuration: number) => void;
}

const CabDurationPickerComponent = ({
  overrides, sx, durationMinutes, onChange, size = "small"
}: ICabDurationPicker): ReactElement => {
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  const props: Omit<FormControlProps, 'children'> = {
    sx,
  };

  useEffect(() => {
    const durationHoursOnly = Math.floor(durationMinutes / 60);
    const durationMinutesOnly = durationMinutes % 60;
    setHours(durationHoursOnly);
    setMinutes(durationMinutesOnly);
  }, [durationMinutes]);

  const handleChangeHours = (value: number | number[] | null) => {
    let newHours = value !== null && !Array.isArray(value) ? value : 0;
    if (isNaN(newHours)) {
      newHours = 0;
    }
    // Hours should never be allowed to be greater than 99
    newHours = Math.min(99, newHours);
    const durationMinutesOnly = durationMinutes % 60;
    const newDurationMinutes = newHours * 60 + durationMinutesOnly;
    onChange(newDurationMinutes);
  };

  const handleChangeMinutes = (value: number | number[] | null) => {
    let newMinutes = value !== null && !Array.isArray(value) ? value : 0;
    if (isNaN(newMinutes)) {
      newMinutes = 0;
    }
    // Minutes should never be allowed to be greater than 59
    const durationHoursOnly = Math.floor(durationMinutes / 60);
    const newDurationMinutes = Math.min(newMinutes, 59) + durationHoursOnly * 60;
    onChange(newDurationMinutes);
  };
  
  return (
    <FormControl {...props} {...overrides}>
      <MainContainer>
        <InputContainer>
          <CabAutocompleteStyled
            label="Hours"
            size={size}
            value={hours}
            hideArrow
            //onFocus={event => event.target.select()}
            onChange={(value) => handleChangeHours(value)}
            options={MEETING_DURATION_HOURS}
            noOptionsText="--"
            // openOnFocus
            narrow
            autoSelect
            autoHighlight
            blurOnSelect={'mouse'}
            sx={{
              width: 57,
              '& .MuiInputLabel-outlined': {
                left: "-3px"
              }
            }}
          />
        </InputContainer>
        <Separator variant="body2">:</Separator>
        <InputContainer>
          <CabAutocompleteStyled
            label="Mins"
            size={size}
            value={minutes}
            hideArrow
            //onFocus={event => event.target.select()}
            onChange={(value) => handleChangeMinutes(value)}
            options={MEETING_DURATION_MINUTES}
            noOptionsText="--"
            openOnFocus
            narrow
            autoSelect
            autoHighlight
            blurOnSelect={'mouse'}
            sx={{width: 56}}
          />
        </InputContainer>
      </MainContainer>
    </FormControl>
  );
};

const InputContainer = styled('div', {
  label: 'InputContainer',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));

const MainContainer = styled('div', {
  label: 'MainContainer',
})(({ theme }) => ({
  display: 'flex',
}));

const CabAutocompleteStyled = styled<FC<CabAutocompleteProps<number, never>>>(CabAutocomplete, {
  label: 'CabAutocompleteStyled',
})(({ theme }) => ({
  padding: 0,
  'input': {
    textAlign: 'center',
    padding: "0px 8px !important"
  },
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    paddingRight: 6,
  }
}));

const Separator = styled(Typography, {
  label: 'Separator',
})(({ theme }) => ({
  marginTop: 8,
  fontWeight: 700,
  marginLeft: 4,
  marginRight: 4,
  color: theme.palette.mode !== 'dark' ? colors.black900 : colors.white900,
}));

export const CabDurationPicker = memo(CabDurationPickerComponent, (prev, next) => {
  return prev.durationMinutes === next.durationMinutes;
}); 