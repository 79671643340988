import {
  Box,
  Collapse,
  ListItem, ListItemButton, ListItemIcon, ListItemProps,
  ListItemText, styled, Tooltip, useTheme
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { CabComponentProps } from "../../cabStyled";
import colors from "../../../../colors";
import chroma from "chroma-js";
import { PassedComponent } from "../../../../utils/types";
import { CabIcon } from "../../CabIcon";
import { isMobile } from "../../../../utils/screenSizeUtils";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CabToggleChip } from "../../CabToggleChip";
import { IconType } from "react-icons";
import { IoChevronDownOutline, IoLockClosedOutline, IoReorderTwo } from "react-icons/io5";
import { useLocation } from "react-router-dom";

export interface CabNavItemProps extends CabComponentProps<ListItemProps> {
  active: boolean;
  open: boolean;
  key: string;
  Icon?: IconType;
  IconRight?: IconType;
  iconOverride?: PassedComponent<object>;
  text: string;
  locked?: boolean;
  hidden?: boolean;
  onClick?: () => void;
  onClickLocked?: () => void;
  onClickMobile?: () => void;
  sx?: ListItemProps['sx'];
  className?: ListItemProps['className'];
  childItems?: CabNavItemProps[]
  handleNavbarDrawer?: (value: boolean) => void
  sortId?: number;
  sortable?: boolean;
  newIcon?: boolean;
  elementId?: ListItemProps['id'];
  path?: string;
  uncollapseAll?: boolean;
  setUncollapseAll?: (value: boolean) => void
}

type CabNavBarButtonType  = Omit<CabNavItemProps, "key" | "sx" | "className"> & {
  openCollapse: boolean;
};

export const CabNavBarButton = React.forwardRef<HTMLDivElement | null, CabNavBarButtonType>((
  { 
    locked, hidden, text, Icon, IconRight, active, open, iconOverride, childItems, 
    onClickLocked, onClickMobile, onClick, openCollapse, sortable, newIcon, sortId, elementId
  },
  ref
): ReactElement => {
  
  const handleListItemClick = () => {
    if (locked && onClickLocked) {
      onClickLocked();
    } else if (isMobile() && onClickMobile) {
      onClickMobile();
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <>
      {!hidden && (
        <>
          <ListItemButtonStyled onClick={handleListItemClick} open={open} sortable={sortable} ref={ref} 
            id={elementId}>
            <ListItemIconStyled open={open} sortable={sortable}>
              {iconOverride ?
                <Box display='flex' gap={'4px'} alignItems='center'>
                  {sortable && open && (
                    <CabIcon size="small" alt="Close" Icon={IoReorderTwo} />
                  )}
                  {iconOverride.component(iconOverride.props)}
                </Box>
                :
                Icon ? <IconStyled Icon={Icon} alt={text} active={active} /> : <></>
              }
            </ListItemIconStyled>

            <ListItemTextStyled open={open} hidden={!open} primaryTypographyProps={{
              ...(active ? { fontWeight: 700 } : {}),
              color: colors.black900,
              noWrap: true
            }}><Box 
                display="flex"
                flexDirection="row"
                alignItems="center"
                fontSize={15}
              >
                {text}
                {(childItems !== undefined && childItems.length > 0) &&
                  <CabIcon
                    sx={{
                      marginLeft: 1,
                      transform: `${openCollapse ? "rotateX(180deg)" : "rotateX(0deg)"}`
                    }}
                    Icon={IoChevronDownOutline}
                  />
                }
              </Box>
            </ListItemTextStyled>
            {((locked || newIcon) && open)
              ? <ListItemIconStyled open={false}>
                {locked ? (
                  <IconStyled Icon={IoLockClosedOutline} alt='Locked' active={active} />
                ) : (
                  <CabToggleChip label="New" themeColor="primary" sx={{fontsize: '10px', padding: 1}}/>
                )}
              </ListItemIconStyled>
              : (IconRight && open) &&
              <ListItemIconStyled open={false}>
                <IconStyled active={active} Icon={IconRight} alt={text} />
              </ListItemIconStyled>
            }
          </ListItemButtonStyled>
        </>
      )}
    </>
  );
});


export const CabNavItem = (
  { sx, className, locked, hidden, onClickMobile, onClickLocked, onClick, text, Icon, IconRight, active, open, sortId,
    childItems, handleNavbarDrawer, iconOverride, sortable, newIcon, elementId, path, uncollapseAll, setUncollapseAll 
  }: CabNavItemProps): ReactElement => {

  const [openCollapse, setOpenCollapse] = useState(false);
  const [ignoreCollapse, setIgnoreCollapse] = useState(false);

  const theme = useTheme();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (!open) {
      setOpenCollapse(false);
    }
  }, [open]);

  useEffect(() => {
    if (uncollapseAll && setUncollapseAll && !ignoreCollapse ) {
      setOpenCollapse(false);
      setUncollapseAll(false);
    }
  }, [ignoreCollapse, setUncollapseAll, uncollapseAll]);

  useEffect(() => {
    if (ignoreCollapse && openCollapse ) {
      setIgnoreCollapse(false);
    }
  }, [ignoreCollapse, openCollapse]);

  const {
    setNodeRef,
    attributes,
    listeners,
    transition,
    transform
  } = useSortable({id: sortId || -1});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleOpenCollapse = (openCollapseParam: boolean) => {
    if (setUncollapseAll) {
      setIgnoreCollapse(true);
      setUncollapseAll(true);
    }
    setOpenCollapse(openCollapseParam);
    if (handleNavbarDrawer && openCollapseParam) {
      handleNavbarDrawer(openCollapseParam);
    }
  };

  const getClickHandler = (baseHandler: (() => void) | undefined): (() => void) | undefined => {
    if (baseHandler) {
      if ((childItems || []).length > 0) {
        return () => handleOpenCollapse(!openCollapse);
      } else {
        return baseHandler;
      }
    }
    return undefined;
  };

  return (
    !sortable ? (
      <>
        {!hidden && (
          <ListItemStyled disablePadding id={elementId} active={active} sx={sx} className={className}>
            <Tooltip
              placement="right" title={open ? "" : text}
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: theme.typography.body2,
                    color: colors.white900
                  }
                }
              }}
              arrow
            >
              <Box width={'100%'} id={elementId}>
                <CabNavBarButton
                  locked={locked}
                  hidden={hidden}
                  onClickMobile={getClickHandler(onClickMobile)}
                  onClickLocked={getClickHandler(onClickLocked)}
                  onClick={getClickHandler(onClick)}
                  text={text}
                  Icon={Icon}
                  IconRight={IconRight}
                  active={active}
                  open={open}
                  iconOverride={iconOverride}
                  childItems={childItems}
                  openCollapse={openCollapse}
                  newIcon={newIcon}
                  path={path}
                />
              </Box>
            </Tooltip>
            {(locked && !open && !hidden) && 
            <CollapsedLockIcon active={active} alt='Locked' Icon={IoLockClosedOutline} />}
          </ListItemStyled>
        )}
        {childItems && !hidden &&
        <Collapse in={openCollapse}>
          {childItems.map((childItem => {
            return <CabNavItem
              {...childItem}
              key={childItem.key}
              sx={{ marginLeft: childItem.Icon ? 5 : undefined }}
              active={childItem.path === pathname}
            />;
          }))}
        </Collapse>
        }
      </>
    ) : (
      <div ref={setNodeRef} style={style} {...attributes}{...listeners}>
        {!hidden && (
          <ListItemStyled disablePadding active={active} sx={sx} className={className}>
            <CabNavBarButton
              locked={locked}
              hidden={hidden}
              onClickMobile={getClickHandler(onClickMobile)}
              onClickLocked={getClickHandler(onClickLocked)}
              onClick={getClickHandler(onClick)}
              text={text}
              Icon={Icon}
              IconRight={IconRight}
              active={active}
              open={open}
              iconOverride={iconOverride}
              childItems={childItems}
              openCollapse={openCollapse}
              sortable={sortable}
              path={path}
            />
            {(locked && !open && !hidden) && 
            <CollapsedLockIcon active={active} alt='Locked' Icon={IoLockClosedOutline} />}
          </ListItemStyled>
        )}
        {childItems && !hidden &&
        <Collapse in={openCollapse}>
          {childItems.map((childItem => {
            return <CabNavItem {...childItem} key={childItem.key} />;
          }))}
        </Collapse>
        }
      </div>
    )
  );
};

const ListItemTextStyled = styled(
  ListItemText, { shouldForwardProp: (prop) => prop !== 'open', label: "ListItemTextStyled" }
)<{open: boolean}>(({open}) => ({
  opacity: open ? 1 : 0,
  marginTop: 0,
  marginBottom: 0
}));

const ListItemIconStyled = styled(ListItemIcon, {
  shouldForwardProp: (prop: string) => 
    !['open', 'sortable'].includes(prop),
  label: "ListItemIconStyled"
})<{open: boolean, sortable?: boolean}>(({theme, open, sortable}) => ({
  ...(open ? {} : {
    justifyContent: "center",
  }),
  ...(!sortable ? {} : {
    marginRight: 8,
  }),
  minWidth: 36,
  marginTop: 0,
  marginBottom: 0
}));

const IconStyled = styled(CabIcon, {
  shouldForwardProp: prop => prop !== 'active',
  label: "IconStyled"
})<{ Icon: IconType; active: boolean }>(({ theme, active }) => ({
  color: colors.black900,
  fontSize: 22
}));


const CollapsedLockIcon = styled(CabIcon, {
  shouldForwardProp: prop => prop !== 'active',
  label: "CollapsedLockIcon"
})<{ Icon: IconType; active: boolean }>(({ theme, active }) => ({
  ...(active ? {
    backgroundColor: colors.black900,
  } : {
    color: colors.black900,
  }),
  fontSize: 12,
  position: "absolute",
  right: 0,
  top: 0,
  padding: 2,
  borderRadius: 9
}));

const ListItemButtonStyled = styled(
  ListItemButton,
  {
    shouldForwardProp: (prop: string) => 
      !['open', 'sortable'].includes(prop),
    label: "ListItemButtonStyled"
  }
)<{open: boolean, sortable?: boolean}>(({open, sortable}) => ({
  ...(open ? {} : {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'
  }),
  ...(!sortable ? {} : {
    paddingLeft: 2,
  }),
  minHeight: 40,
  paddingTop: 4,
  paddingBottom: 4,
}));

const ListItemStyled = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'active',
  label: "ListItemStyled"
})<CabComponentProps<ListItemProps> & { active: boolean }>(({ active }) => ({
  ...(active ? {
    backgroundColor: colors.black200,
    fontWeight: 700
  } : {
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: chroma(colors.navyDark).alpha(0.04)
    },
  }),
  borderRadius: 8,
  width: 'calc(100% -  16px)',
  margin: 4,
  marginLeft: 8,
  position: "relative",
}));
