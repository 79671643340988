import { ActionType } from '../actionTypes';
import { MeetingSlot, RootAction, ScheduleState } from '..';
import { removeKeysFromObject } from '../../utils/dataUtils';
import { updateCalendarsFromRemoteCalendars } from '../../utils/scheduleUtils';
import { DateTime } from 'luxon';

export const initialState: ScheduleState = {
  calendars: [],
  calendarErrors: [],
  newMeeting: null,
  meetings: {},
  meetingSlots: [],
  events: {},
  participantAutoCompletOptions: [],
  schedulingPrefs: {
    leader_prefs: {},
  },
  zoomSettings: {},
  associationsLoaded: false,
  meetingsLoaded: false,
  calendarsLoaded: false,
  slotsLoaded: false,
  scheduledMeetingsLoaded: false,
  scheduledSlotsLoaded: false,
  publicMeetings: {},
  pollSelections: {},
  externalParticipants: {},
  scheduleErrors: {},
  questionAnswers: {},
  meetingRooms: {},
  meetingAuditLogsLoaded: false,
  meetingAuditLogs: {},
  recentlyScheduledLoaded: false,
  recentlyScheduled: {},
  presetLocations: {},
  lastUpdateTimes: {},
};

export default function schedule(state = initialState, action: RootAction): typeof initialState {

  switch (action.type) {
    case ActionType.FETCHED_CALENDAR_ASSOCIATIONS:
      return { 
        ...state,
        calendars: action.calendars.sort((a, b) => {
          if (a.summary < b.summary) return -1;
          if (a.summary > b.summary) return 1;
          return 0;
        }),
        associationsLoaded: true
      };

    case ActionType.CREATED_CALENDAR:
      return {
        ...state, calendars: [
          ...state.calendars,
          action.calendar
        ].sort((a, b) => {
          if (a.summary < b.summary) return -1;
          if (a.summary > b.summary) return 1;
          return 0;
        }),
      };

    case ActionType.UPDATED_CALENDAR:
      return {
        ...state,
        calendars: [
          ...state.calendars.filter(cal => cal.id !== action.calendar.id),
          action.calendar,
        ].sort((a, b) => {
          if (a.summary < b.summary) return -1;
          if (a.summary > b.summary) return 1;
          return 0;
        }),
      };

    case ActionType.SAVED_MEETING_SLOTS: {
      const newLastUpdateTimes = {...state.lastUpdateTimes};
      action.meetingSlots.forEach(slots => newLastUpdateTimes[slots.meeting] = DateTime.now().toMillis());

      return { 
        ...state, 
        meetingSlots: [...state.meetingSlots, ...action.meetingSlots], 
        lastUpdateTimes: newLastUpdateTimes
      };
    }

    case ActionType.SAVED_MEETING_QUESTIONS: {
      const meeting = action.isSavedMeeting
        ? state.meetings[action.meetingId]
        : state.newMeeting;

      const updatedMeeting = {
        ...meeting,
        questions: {
          ...meeting?.questions,
          ...action.meetingQuestions
            .map(q => ({ [q.id]: q }))
            .reduce((a, b) => ({ ...a, ...b }), {}),
        },
      };

      if (action.isSavedMeeting) {
        return {
          ...state,
          meetings: {
            ...state.meetings,
            [action.meetingId]: updatedMeeting,
          },
          lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingId]: DateTime.now().toMillis()}
        };
      }

      return {
        ...state,
        newMeeting: { ...updatedMeeting, id: -1 },
      };
    }

    case ActionType.SAVED_MEETING:
      return { 
        ...state, 
        meetings: { 
          ...state.meetings, [action.meeting.id]: action.meeting 
        },
        lastUpdateTimes: {...state.lastUpdateTimes, [action.meeting.id]: DateTime.now().toMillis()}   
      };

    case ActionType.FETCHING_AVAILABLE_CALENDARS:
      return { ...state, calendarsLoaded: false };

    case ActionType.FETCHED_AVAILABLE_CALENDARS:

      return {
        ...state,
        calendars: updateCalendarsFromRemoteCalendars(state.calendars, action.remoteCalendars).sort((a, b) => {
          if (a.summary < b.summary) return -1;
          if (a.summary > b.summary) return 1;
          return 0;
        }),
        calendarErrors: action.errors,
        calendarsLoaded: true
      };


    case ActionType.FETCHED_MEETINGS: {
      const updatedMeetings = {...action.meetings};
      
      // If a meeting were updated after the initial fetch, keep the local version
      const fetchTime = DateTime.fromISO(action.fetchStartTime);
      Object.values(state.meetings)
        .filter(m => 
          (m.updated_at && DateTime.fromISO(m.updated_at) > fetchTime) 
          || (state.lastUpdateTimes[m.id] && DateTime.fromMillis(state.lastUpdateTimes[m.id]) > fetchTime)
        ).forEach(m => {
          delete updatedMeetings[m.id];
        });

      return {
        ...state,
        meetings: {
          ...state.meetings,
          ...updatedMeetings,
        },
        meetingsLoaded: true,
        scheduledMeetingsLoaded: action.scheduledMeetingsLoaded,
      };
    }
    case ActionType.FETCHED_MEETING:
      return {
        ...state,
        meetings: { ...state.meetings, [action.meeting.id]: action.meeting }
      };

    case ActionType.UPDATED_MEETING:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          [action.meeting.id]: { ...state.meetings[action.meeting.id], ...action.meeting },
        },
        lastUpdateTimes: {...state.lastUpdateTimes, [action.meeting.id]: DateTime.now().toMillis()}
      };

    case ActionType.DELETED_MEETING: {
      const newMeetings = { ...state.meetings };
      delete newMeetings[action.meeting.id];
      return { 
        ...state, 
        meetings: newMeetings, 
        lastUpdateTimes: {...state.lastUpdateTimes, [action.meeting.id]: DateTime.now().toMillis()} 
      };
    }

    case ActionType.DELETED_MEETINGS: {
      const newMeetings = { ...state.meetings };
      const newLastUpdateTimes = {...state.lastUpdateTimes};

      action.meetingIds.forEach(id => {
        delete newMeetings[id];
        newLastUpdateTimes[id] = DateTime.now().toMillis();
      });
      return { ...state, meetings: newMeetings, lastUpdateTimes: newLastUpdateTimes };
    }

    case ActionType.UPDATED_NEW_MEETING:
      return {
        ...state,
        newMeeting: {
          ...state.newMeeting,
          ...action.meeting,
          id: -1,
        },
      };

    case ActionType.DELETED_NEW_MEETING:
      return {
        ...state,
        newMeeting: null,
      };

    case ActionType.FETCHED_MEETING_SLOTS: {
      const dedupedMeetingSlots: {[key: number]: MeetingSlot} = {};
      [...state.meetingSlots, ...action.meetingSlots].forEach(s => dedupedMeetingSlots[s.id] = s);

      return {
        ...state,
        // deep merge slots by id
        // meetingSlots: Object.values([...state.meetingSlots, ...action.meetingSlots].map(s => ({ [s.id]: s }))
        //   .reduce((a, b) => {
        //     const bVal = Object.values(b)[0];
        //     if (!bVal) return a;

        //     return {
        //       ...a,
        //       [bVal.id]: { ...a[bVal.id], ...bVal }
        //     };
        //   }, {})
        // ),
        meetingSlots: Object.values(dedupedMeetingSlots),
        slotsLoaded: true,
        scheduledSlotsLoaded: !action.unscheduled,
      };
    }
    // This will additionally load in slots for a single meeting, and if we use this we assume it's 
    //    unscheduled and that's why we need it.
    // WARNING: If this is executed AFTER scheduled slots are fetched, it will result in duplicate slots
    case ActionType.FETCHED_SLOTS_FOR_MEETING:
      return { ...state, meetingSlots: [...state.meetingSlots, ...action.meetingSlots] };

    case ActionType.FETCHED_MEETING_EXTERNAL:
      return {
        ...state,
        publicMeetings: {
          ...state.publicMeetings,
          [action.meeting.external_id]: action.meeting,
        },
        externalParticipants: action.meeting.participants
      };

    case ActionType.SET_POLL_SELECTIONS:
      return {
        ...state,
        publicMeetings: {
          ...state.publicMeetings,
          [action.externalId]: {
            ...state.publicMeetings[action.externalId],
            poll_selections: {
              ...state.publicMeetings[action.externalId].poll_selections,
              ...action.pollSelections,
            },
            participants: {
              ...action.externalParticipants,
              ...state.publicMeetings[action.externalId].participants
            }
          }
        }
      };

    case ActionType.DELETED_POLL_SELECTIONS:
      return {
        ...state,
        publicMeetings: {
          ...state.publicMeetings,
          [action.externalId]: {
            ...state.publicMeetings[action.externalId],
            poll_selections: removeKeysFromObject(
              state.publicMeetings[action.externalId].poll_selections, action.deletedSelectionIds
            )
          }
        }
      };

    case ActionType.FETCHED_PARTICIPANT_AUTOCOMPLETE_OPTIONS:
      return {...state, participantAutoCompletOptions: action.options};

    case ActionType.FETCHED_POLL_SELECTIONS:
      return {
        ...state,
        pollSelections: { ...state.pollSelections, ...action.pollSelections },
        externalParticipants: { ...state.externalParticipants, ...action.externalParticipants }
      };

    case ActionType.FETCHED_POLL_RESULTS:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          ...action.meetings,
        }
      };

    case ActionType.FETCHED_REMOTE_CALENDAR_EVENTS:
      return { ...state, events: { ...state.events, ...action.events } };

    case ActionType.UPDATED_MEETING_SLOT: {
      const newMeetingSlots = state.meetingSlots.slice();
      const indexToEdit = state.meetingSlots.findIndex(slot => action.meetingSlot.id === slot.id);
      if (indexToEdit > -1) {
        newMeetingSlots[indexToEdit] = action.meetingSlot;
      }
      return { 
        ...state, 
        meetingSlots: newMeetingSlots, 
        lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingSlot.meeting]: DateTime.now().toMillis()} 
      };
    }

    case ActionType.DELETED_MEETING_SLOT: {
      const newMeetingSlots = state.meetingSlots.filter(slot => slot.id !== action.slotId);
      return { 
        ...state, 
        meetingSlots: newMeetingSlots,
        lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingId]: DateTime.now().toMillis()}
      };
    }

    case ActionType.DELETED_MEETING_SLOTS: {
      const slotIdsToRemove = new Set(action.slotIds);
      const newLastUpdateTimes = {...state.lastUpdateTimes};
      const newMeetingSlots: MeetingSlot[] = [];
      state.meetingSlots.forEach(slot => {
        if (slotIdsToRemove.has(slot.id)) {
          newLastUpdateTimes[slot.meeting] = DateTime.now().toMillis();
        } else {
          newMeetingSlots.push(slot);
        }
      });
      return { ...state, meetingSlots: newMeetingSlots, lastUpdateTimes: newLastUpdateTimes };
    }

    case ActionType.UPDATED_MEETING_QUESTION: {
      const meeting = action.isSavedMeeting
        ? state.meetings[action.meetingQuestion.meeting]
        : state.newMeeting;

      const updatedMeeting = {
        ...meeting,
        questions: {
          ...meeting?.questions, 
          [action.meetingQuestion.id]: action.meetingQuestion
        }
      };

      if (action.isSavedMeeting) {
        return {
          ...state,
          meetings: {
            ...state.meetings,
            [action.meetingQuestion.meeting]: updatedMeeting,
          },
          lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingQuestion.meeting]: DateTime.now().toMillis()}
        };
      }

      return {
        ...state,
        newMeeting: { ...updatedMeeting, id: -1 },
      };
    }

    case ActionType.DELETED_MEETING_QUESTIONS: {
      const meeting = action.isSavedMeeting
        ? state.meetings[action.meetingId]
        : state.newMeeting;

      const newQuestions = { ...meeting?.questions };
      action.questionIds.forEach(id => delete newQuestions[id]);

      const updatedMeeting = {
        ...meeting,
        questions: newQuestions,
      };

      if (action.isSavedMeeting) {
        return { 
          ...state, 
          meetings: {
            ...state.meetings, 
            [action.meetingId]: updatedMeeting,
          },
          lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingId]: DateTime.now().toMillis()}
        };
      }

      return {
        ...state,
        newMeeting: { ...updatedMeeting, id: -1 },
      };
    }

    case ActionType.FETCHED_ZOOM_SETTINGS: {
      const zoomSettings = action.conferenceSettings;
      return { ...state, zoomSettings };
    }
    case ActionType.UPDATED_ZOOM_SETTINGS: {
      const newConferenceSettings = { ...state.zoomSettings };
      newConferenceSettings[action.conferenceSettings.id] = action.conferenceSettings;
      return { ...state, zoomSettings: newConferenceSettings };
    }

    case ActionType.FETCHED_SCHEDULING_PREFS: {
      const prefs = action.preferences;
      return { ...state, schedulingPrefs: prefs };
    }

    case ActionType.UPDATED_SCHEDULING_PREFS: {
      const newSchedulingPrefs = { ...state.schedulingPrefs };
      if (action.preferences.user) {
        newSchedulingPrefs.user_prefs = action.preferences;
      } else {
        newSchedulingPrefs.leader_prefs[action.preferences.id] = action.preferences;
      }

      return { ...state, schedulingPrefs: newSchedulingPrefs };
    }

    case ActionType.SET_SCHEDULE_ERRORS: {
      return {...state, scheduleErrors: action.errors };
    }
    case ActionType.SET_MEETING_SCHEDULE_ERRORS: {
      return {...state, scheduleErrors: {...state.scheduleErrors, [action.meetingId]: action.errors}};
    }
    case ActionType.SET_MEETING_QUESTION_ANSWERS: {
      return {...state, questionAnswers: action.questionAnswers};
    }
    case ActionType.SET_EXTERNAL_PARTICIPANT: {
      return {
        ...state,
        externalParticipants: {
          ...state.externalParticipants,
          [action.externalParticipant.id]: action.externalParticipant
        },
        lastUpdateTimes: {...state.lastUpdateTimes, [action.externalParticipant.meeting]: DateTime.now().toMillis()}
      };
    }

    case ActionType.SET_MEETING_PARTICIPANT: {
      const meeting = action.isSavedMeeting
        ? state.meetings[action.externalParticipant.meeting]
        : state.newMeeting;

      const updatedExternalParticipants = {
        ...meeting?.participants,
        [action.externalParticipant.id]: action.externalParticipant,
      };
      const expectedParticipants = meeting?.num_expected_participants || 1;
      const updatedMeeting = {
        ...meeting,
        participants: updatedExternalParticipants,
        num_expected_participants: Math.max(Object.keys(updatedExternalParticipants).length, expectedParticipants),
      };

      if (action.isSavedMeeting) {
        return {
          ...state,
          meetings: {
            ...state.meetings,
            [action.externalParticipant.meeting]: updatedMeeting,
          },
          lastUpdateTimes: {...state.lastUpdateTimes, [action.externalParticipant.meeting]: DateTime.now().toMillis()}
        };
      }

      return { ...state, newMeeting: { ...updatedMeeting, id: -1 } };
    }

    case ActionType.UPDATED_MEETING_PARTICIPANT: {
      const meeting = action.isSavedMeeting
        ? state.meetings[action.meetingId]
        : state.newMeeting;

      const updatedMeeting = {
        ...meeting,
        participants: {
          ...meeting?.participants,
          [action.participantId]: {
            ...meeting?.participants?.[action.participantId],
            ...action.externalParticipant,
          },
        },
      };

      if (action.isSavedMeeting) {
        return {
          ...state,
          meetings: {
            ...state.meetings,
            [action.meetingId]: updatedMeeting,
          },
          lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingId]: DateTime.now().toMillis()}
        };
      }

      return { ...state, newMeeting: { ...updatedMeeting, id: -1 } };
    }

    case ActionType.DELETED_MEETING_PARTICIPANT: {
      const meeting = action.isSavedMeeting
        ? state.meetings[action.meetingId]
        : state.newMeeting;

      const newExternalParticipants = { ...meeting?.participants };
      delete newExternalParticipants[action.participantId];

      const newPollSelections = meeting?.poll_selections ? { ...meeting?.poll_selections } : undefined;
      if (meeting?.poll_selections && newPollSelections) {
        Object.entries(meeting?.poll_selections).forEach(([k, v]) => {
          if (v.participant === action.participantId.toString()) {
            delete newPollSelections[k];
          }
        });
      }

      const updatedMeeting = {
        ...meeting,
        participants: newExternalParticipants,
        poll_selections: newPollSelections,
      };

      if (action.isSavedMeeting) {
        return {
          ...state,
          meetings: {
            ...state.meetings,
            [action.meetingId]: updatedMeeting,
          },
          lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingId]: DateTime.now().toMillis()}
        };
      }

      return { ...state, newMeeting: { ...updatedMeeting, id: -1 } };
    }

    case ActionType.UPDATED_MEETING_LEADER: {
      const meeting = action.isSavedMeeting
        ? state.meetings[action.meetingId]
        : state.newMeeting;

      const leaderInfo = (meeting?.leader_info || []).map(l => l.id === action.leaderId
        ? { ...l, ...action.meetingLeader, id: action.leaderId } : l);

      const updatedMeeting = {
        ...meeting,
        leader_info: leaderInfo,
      };

      if (action.isSavedMeeting) {
        return {
          ...state,
          meetings: {
            ...state.meetings,
            [action.meetingId]: updatedMeeting,
          },
          lastUpdateTimes: {...state.lastUpdateTimes, [action.meetingId]: DateTime.now().toMillis()}
        };
      }

      return { ...state, newMeeting: { ...updatedMeeting, id: -1 } };
    }

    case ActionType.SET_MEETING_ROOMS: {
      return {
        ...state,
        meetingRooms: action.rooms
      };
    }

    case ActionType.SET_MEETING_AUDIT_LOGS: {
      return {
        ...state,
        meetingAuditLogs: action.auditLogs,
        meetingAuditLogsLoaded: true
      };
    }

    case ActionType.SET_RECENTLY_SCHEDULED: {
      return {
        ...state,
        recentlyScheduled: action.meetings,
        recentlyScheduledLoaded: true
      };
    }

    case ActionType.FETCHED_PRESET_LOCATIONS: {
      return { ...state, presetLocations: action.locations };
    }

    case ActionType.UPDATED_PRESET_LOCATION: {
      return {
        ...state,
        presetLocations: {
          ...state.presetLocations,
          [action.id]: {
            ...action.location,
          },
        },
      };
    }

    case ActionType.DELETED_PRESET_LOCATION: {
      const { [action.id]: removedLoc, ...newPresetLocations } = state.presetLocations;
      return {
        ...state,
        presetLocations: newPresetLocations,
      };
    }

    default:
      return state;
  }
}