import { API_URLS }  from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';
import { 
  Grant, OAuthProvider, User,
  ZoomUserContactsSearchResult, ZoomUserSchedulersSearchResult,
  UserPrefs, AuthAction, ReferenceType,
} from './types';
import {makeHeaders, fetchData, makeParams } from '../../utils/apiUtils';
import { GlobalModalComponentName, RootState } from '..';
import {
  getException, COGNITO_ERROR_CODE, AmplifyError, CABINET_AUTH_ERROR_CODE,
  isAmplifyError, INDETERMINATE_AUTH_ERROR_MESSAGE,
} from '../../utils/cognitoErrorUtils';
import {addBreadcrumb} from '@sentry/react';
import { postLoginCookieCleanup, removeLocalStorageOnboarding } from '../../utils/storageUtils';
import { EVENT_TYPE, MICROSOFT_INCREMENTAL_SCOPES_LS_KEY, PAGE_URL, PROVIDER, PROVIDER_BY_NAME } from '../../constants';
import { trackEvent, trackSignupComplete } from '../../utils/appAnalyticsUtils';
import { Preferences } from '@capacitor/preferences';
import { fetchOrganization } from '../organization/actions';
import api from '../../api';
import { sendMessage } from '../globalMessages/actions';
import { openModal } from '../globalModal/actions';
import { DateTime } from 'luxon';
import { AlertColor } from '@mui/material';
import { cabCaptureException, cabCaptureMessage } from '../../utils/logging';
import { AmplifyAuthProvider, setAuthErrorToSessionStorage } from '../../utils/authUtils';
import { 
  fetchAuthSession as amplifyFetchAuthSession,
  fetchUserAttributes as amplifyFetchUserAttributes,
  resetPassword as amplifyResetPassword,
  confirmResetPassword as amplifyConfirmResetPassword,
  updatePassword as amplifyUpdatePassword,
  updateUserAttribute as amplifyUpdateUserAttribute,
  confirmUserAttribute as amplifyConfirmUserAttribute,
  signUp as amplifySignUp,
  confirmSignUp as amplifyConfirmSignUp,
  signIn as amplifySignIn,
  signInWithRedirect as amplifySignInWithRedirect,
  signOut as amplifySignOut,
  ResetPasswordOutput,
  UpdateUserAttributeOutput,
  ConfirmUserAttributeInput,
  FetchUserAttributesOutput,
  SignOutInput
} from 'aws-amplify/auth';
import { createSearchParams } from 'react-router-dom';

const cognitoCodeHandler = (err: unknown): {
  message: string, canRequestNewCode: boolean, errorType: COGNITO_ERROR_CODE
} => {
  let errorType: COGNITO_ERROR_CODE = COGNITO_ERROR_CODE.UNKOWN_EXCEPTION;
  let message = "";
  let canRequestNewCode = true;
  if (isAmplifyError(err)) {
    // Assigning errorType within cases for proper typing
    switch (err.code) {
      case COGNITO_ERROR_CODE.CODE_MISMATCH_EXCEPTION:
        errorType = err.code;
        message = 'That verification code is not correct. Try again or';
        canRequestNewCode = true;
        break;
      case COGNITO_ERROR_CODE.EXPIRED_CODE_EXCEPTION: 
        errorType = err.code;
        message = 'This verification link is no longer valid. Check your inbox for a newer link, or';
        canRequestNewCode = true;
        break;
      case COGNITO_ERROR_CODE.USER_NOT_FOUND_EXCEPTION:
        errorType = err.code;
        message = INDETERMINATE_AUTH_ERROR_MESSAGE;
        canRequestNewCode = false;
        break;
      case COGNITO_ERROR_CODE.USERNAME_EXISTS_EXCEPTION: 
        errorType = err.code;
        message = INDETERMINATE_AUTH_ERROR_MESSAGE;
        canRequestNewCode = false;
        break;
      case COGNITO_ERROR_CODE.LIMIT_EXCEEDED_EXCEPTION: 
        errorType = err.code;
        message = 'Please wait a few minutes and try again, then';
        canRequestNewCode = true;
        break;
      case COGNITO_ERROR_CODE.PASSWORD_RESET_REQUIRED_EXCEPTION: 
        errorType = err.code;
        message = 'Your password needs to be reset for security reasons. Please use "Forgot your password?" below.';
        canRequestNewCode = true;
        break;
      default:
        message = 'Something went wrong. Please email help@joincabinet.com for assistance.';
        break;
    }
  } else {
    message = 'Something went wrong. Please email help@joincabinet.com for assistance.';
  }
  
  return {message, canRequestNewCode, errorType};
};

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CALENDAR_CLIENT_ID;

const handleAmplifyError = (error: AmplifyError, dispatch: ThunkDispatchType) => {
  const errorObj = getException(error);
  const { errorType } = errorObj;
  switch (errorType) {
    case COGNITO_ERROR_CODE.PASSWORD_RESET_REQUIRED_EXCEPTION:
      dispatch({type: ActionType.PASSWORD_CHANGE_REQUIRED});
      break;
    case COGNITO_ERROR_CODE.NOT_AUTHORIZED_EXCEPTION:
      dispatch({type: ActionType.AUTHENTICATION_ERROR});
      break;
    case COGNITO_ERROR_CODE.EXPIRED_CODE_EXCEPTION:
    case COGNITO_ERROR_CODE.CODE_MISMATCH_EXCEPTION:
    case COGNITO_ERROR_CODE.INVALID_PASSWORD_EXCEPTION:
    case COGNITO_ERROR_CODE.INVALID_PARAMETER_EXCEPTION:
    case COGNITO_ERROR_CODE.LIMIT_EXCEEDED_EXCEPTION:
      //Don't throw login failed
      break;
    default:
      dispatch({type: ActionType.LOGIN_FAILED});
  }

  return errorObj;
};

export const checkReferralCode = (): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const referralKey = 'referralCode';
    const { value } = await Preferences.get({ key: referralKey });
    if (value) {
      const headers = await makeHeaders(true);
      const body = JSON.stringify({ referralCode: value });
      const res = await fetchData(API_URLS.TRACK_REFERRAL, { headers, body, method: 'POST' });
      if (res.status === 200) {
        await Preferences.remove({ key: referralKey });
      }
    }
  };

export const finishedOnboarding = (): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ profile: { app_onboarding_completed: true } });
    const user = getState().auth.user;

    dispatch({ type: ActionType.UPDATE_ONBOARDING_COMPLETED, completed: true });

    const res = await fetchData(`${API_URLS.PROFILE}${user?.id || -1}/`, { headers, method: 'PATCH', body });
    if (res.status !== 200) {
      dispatch({ type: ActionType.UPDATE_ONBOARDING_COMPLETED, completed: false });
    }
  };

export const setProfileReferenceType = (referenceType: ReferenceType | null): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ profile: { reference_type: referenceType } });
    const user = getState().auth.user;

    const res = await fetchData(`${API_URLS.PROFILE}${user?.id || -1}/`, { headers, method: 'PATCH', body });
    if (res.status === 200) {
      dispatch({ type: ActionType.UPDATE_REFERENCE_TYPE, referenceType: referenceType });
    }
  };

export const loadUser = (login = false): ThunkResult<Promise<User | Record<string, unknown> | undefined>> =>
  async (
    dispatch: ThunkDispatchType, getState: () => RootState
  ): Promise<User | Record<string, unknown> | undefined> => {
    dispatch({type: ActionType.USER_LOADING});
    const headers = await makeHeaders(true);
    try {
      await amplifyFetchAuthSession();
      await postLoginCookieCleanup();
      const url = `${API_URLS.AUTH_USER}${login ? "?login=true" : ""}`;
      return fetchData<User, CabinetAuthError>(
        url, { headers, }
      ).then(async (res): Promise<User | Record<string, unknown>| undefined >  => {
        if (res.status === 200) {
          const user = res.data;
          const showOnboarding = !user.profile.app_onboarding_completed;
          dispatch({ type: ActionType.USER_LOADED, user, email: user.email, showOnboarding});
          dispatch(checkReferralCode());
          const orgRes = await dispatch(fetchOrganization(user.active_license.organization));
          if (orgRes?.status === 200 && !user?.features.UNPAID_LICENSE) {
            if (
              orgRes.data.active && orgRes.data.trialing &&
              orgRes.data.unpaid_trial_end_date
            ) {
              const trialingDate = DateTime.fromISO(orgRes.data.unpaid_trial_end_date);
              const timeLeftOnTrial = trialingDate.diff(DateTime.now(), ["days", "hours", "minutes", "seconds"]);
              let severity: AlertColor = "info";
              let message = "";
              if (timeLeftOnTrial.days > 1) {
                message = `You have ${timeLeftOnTrial.days} day${timeLeftOnTrial.days === 1 ? '' : 's'} 
                  left on your trial`;
              } else if (timeLeftOnTrial.hours > 1) {
                message = `You have ${timeLeftOnTrial.hours} hour${timeLeftOnTrial.hours === 1 ? '' : 's'} 
                  left on your trial`;
                severity = "warning";
              } else if (timeLeftOnTrial.minutes > 1) {
                message = `You have ${timeLeftOnTrial.minutes} minute${timeLeftOnTrial.minutes === 1 ? '' : 's'} 
                  left on your trial`;
                severity = "error";
              } else if (timeLeftOnTrial.seconds > 0 && !window.location.href.includes(PAGE_URL.MY_TEAM)) {
                dispatch(setAuthRedirect(PAGE_URL.MY_TEAM));
                // NOTE: Need to escape to prevent potential furhter routing
                return;
              }
              // Only notify user if trial will end in 90 days or less
              if (message && timeLeftOnTrial.toMillis()  < 90 * 24 * 3600 * 1000) {
                dispatch(sendMessage({
                  message: message,
                  timeout: 10000,
                  severity: severity,
                  position: {
                    vertical: "bottom",
                    horizontal: "center"
                  },
                  active: true,
                  header: "",
                  autoDismiss: false
                }));
              }
            } else if (!orgRes.data.active && !orgRes.data.trialing && !showOnboarding && 
              (orgRes.data.unpaid_trial_end_date !== null && 
                DateTime.fromISO(orgRes.data.unpaid_trial_end_date).toMillis() <= DateTime.now().toMillis())) {
              if (window.location.href.includes(PAGE_URL.MY_TEAM)) {
                dispatch(openModal(GlobalModalComponentName.TRIAL_EXPIRED));
              } else {
                dispatch(setAuthRedirect(PAGE_URL.MY_TEAM));
                // NOTE: Need to escape to prevent potential furhter routing
                return;
              }
            }
            if (window.location.pathname === PAGE_URL.LOGIN) {
              dispatch(setAuthRedirect(PAGE_URL.DASHBOARD));
            }
          }
          return res.data;
        } else if (res.status !== 204) {
          if (res.status === 401 || res.status === 400) {
            if (res.data.detail.message) {
              setAuthErrorToSessionStorage([res.data.detail.message]);
            }
            if (res.data.detail.errorType === CABINET_AUTH_ERROR_CODE.FEDERATED_ERROR) {
              dispatch(sendMessage({
                timeout: 5000,
                message: "Please login using SSO/Google",
                autoDismiss: false,
                header: "",
                position: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                active: true,
                severity: "error",
              }));
            }
            dispatch(logout());
          }
          dispatch({ type: ActionType.AUTHENTICATION_ERROR});
          return res.data;
        }
      }).catch(err => {
        dispatch({ type: ActionType.LOGIN_FAILED });
        return err;
      });
      
    } catch {
      dispatch({ type: ActionType.LOGIN_FAILED });
    }
  };

type CabinetAuthError = { 
  errorType: COGNITO_ERROR_CODE | CABINET_AUTH_ERROR_CODE; 
  message: string;
  extra?: Record<string, string>;
};

// Sometimes auth errors are forced into the "FetchReturn" type, in which case we can't have a boolean property.
// This should be resolved in the future.
type CabinetAuthErrorCanRequestCode = CabinetAuthError & { canRequestNewCode?: boolean };


const initializeGoogleClient = (scope: string, cb: (r: google.accounts.oauth2.CodeResponse) => void) => {
  if (GOOGLE_CLIENT_ID) {
    return google.accounts.oauth2.initCodeClient({
      client_id: GOOGLE_CLIENT_ID,
      callback: cb,
      scope: scope
    });
  }
  throw Error("Google ClientID must be defined");
};


export const login = (
  email: string, password: string, stayLoggedIn: boolean
): ThunkResult<Promise<FetchReturn<User, CabinetAuthError> | undefined>> =>
  async (
    dispatch: ThunkDispatchType
  ): Promise<FetchReturn<User, CabinetAuthError> | undefined> => {
    const emailLower = email ? email.toLocaleLowerCase() : ''; 
    
    try {
      const { nextStep } = await amplifySignIn({username: emailLower, password: password || ''});
      if (nextStep.signInStep === 'RESET_PASSWORD') {
        dispatch(sendMessage({
          message: 'Password Reset Required',
          timeout: 5000,
          severity: 'error',
          position: { vertical: "top", horizontal: "center" },
          active: true,
          header: "",
          autoDismiss: true,
        }));

        const searchParams = createSearchParams({ verifiedEmail: emailLower, allowRequestNewCode: 'true' });
        dispatch(setAuthRedirect(PAGE_URL.RESET_PASS + `?${searchParams}`));
      }
    } catch (error) {
      const result = handleAmplifyError(error as AmplifyError, dispatch);
      const checkIdentityResult = await api.checkIdentity(email);
      
      if (
        checkIdentityResult.status === 200 && checkIdentityResult.data.code === "login-with-sso"
      ) {
        dispatch(setAuthRedirect(PAGE_URL.LOGIN + `/${checkIdentityResult.data.detail}`));
      } else if (
        checkIdentityResult.status === 200 && checkIdentityResult.data.code === "login-with-google"
      ) {
        return {
          status: 400,
          data: {
            detail: {
              message: "Sign in with Google",
              errorType: CABINET_AUTH_ERROR_CODE.LOGIN_IN_WITH_GOOGLE
            },
            code: CABINET_AUTH_ERROR_CODE.LOGIN_IN_WITH_GOOGLE
          }
        };
      } else {
        return {
          status: 400,
          data: {
            detail: result,
            code: result.errorType
          }
        };
      }
    }
  };

export const register = (
  email: string, password: string, firstName: string, lastName: string, referralCode?: string
): ThunkResult<Promise<CabinetAuthError | undefined>> =>
  async (dispatch: ThunkDispatchType) => {
    const emailLower = email.toLowerCase(); 

    const attributes = {
      email: emailLower,
      given_name: firstName,
      family_name: lastName,
    };

    try {
      await amplifySignUp({
        username: emailLower,
        password: password,
        options: {
          userAttributes: attributes
        }
      });
    } catch (error) {
      // NOTE: This caused errors not to be shown on signup
      //dispatch(logout());
      return handleAmplifyError(error as AmplifyError, dispatch);
    }
  };


export const confirmSignup = (
  email: string, code: string,
): ThunkResult<Promise<CabinetAuthErrorCanRequestCode | undefined>> =>
  async (dispatch: ThunkDispatchType)  => {
    let errorType;
    let message;
    let canRequestNewCode;
    const emailLower = email.toLocaleLowerCase(); 

    try {
      await amplifyConfirmSignUp({username: emailLower, confirmationCode: code});
      // If confirmSignUp returns successfully, the user was created. This should fire only 1 time per user.
      trackSignupComplete();
      return;
    } catch (err: unknown) {
      const {
        errorType: cogErrorType,
        message: cogMessage,
        canRequestNewCode: cogCanRequestNewCode
      } = cognitoCodeHandler(err);
      errorType = cogErrorType;
      message = cogMessage;
      canRequestNewCode = cogCanRequestNewCode;
    }

    return { errorType, message, canRequestNewCode };
  };


export const startForgotPassword = (email: string): ThunkResult<Promise<ResetPasswordOutput | CabinetAuthError>> =>
  async (dispatch: ThunkDispatchType) => {
    const email_lower = email.toLowerCase();

    addBreadcrumb({
      category: 'auth',
      message: 'User email: ' + email_lower,
      level: "info"
    });

    cabCaptureMessage(`Password Reset Requested for Email: ${email_lower}`); 

    const idRes = await api.checkIdentity(email);
    if ('data' in idRes) {
      if (idRes.data.code === 'login-with-sso') {
        return {
          errorType: CABINET_AUTH_ERROR_CODE.SSO_REQUIRED,
          message: 'Login with SSO',
          extra: idRes.data.detail ? { orgCode: idRes.data.detail } : undefined,
        };
      } else if (idRes.data.code === 'login-with-google') {
        return { errorType: CABINET_AUTH_ERROR_CODE.LOGIN_IN_WITH_GOOGLE, message: 'Login with Google' };
      }
    }

    return await amplifyResetPassword({username: email_lower})
      .then(data => {
        dispatch({type: ActionType.PASSWORD_CHANGE_REQUIRED});
        return data;
      })
      .catch(error => {
        return handleAmplifyError(error, dispatch);
      });
  };

export const confirmForgotPassword = (
  email: string, code: string, newPassword = ''
): ThunkResult<Promise<CabinetAuthError | void>> =>
  async (dispatch: ThunkDispatchType) => {
    const email_lower = email.toLowerCase();

    addBreadcrumb({
      category: 'auth',
      message: 'User email: ' + email_lower,
      level: "info"
    });
    cabCaptureMessage(`Password Reset Requested for Email: ${email_lower}`); 

    return amplifyConfirmResetPassword({username: email_lower, confirmationCode: code, newPassword})
      .then(data =>  {
        dispatch({type: ActionType.PASSWORD_CHANGE_SUCCESFUL});
        return;
      })
      .catch(error => handleAmplifyError(error, dispatch));


    // return fetchData(API_URLS.AUTH_CONFIRM_FORGOT_PASSWORD, {headers, body, method: 'POST'})
    //   .then((res: FetchReturn): Record<string, unknown> => {
    //     if (res.status === 200) {
    //       dispatch({type: ActionType.PASSWORD_CHANGE_SUCCESFUL});
    //     }
    //     return res.data;
    //   });
  };

export const cancelChangePassword = (): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    dispatch({type: ActionType.PASSWORD_CHANGE_CANCELLED});
  };

export const changePassword = (
  oldPassword: string, newPassword: string
): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {
    try {
      const data = await amplifyUpdatePassword({oldPassword, newPassword});
      return { data };
    } catch (err) {
      const e = getException(err as AmplifyError);
      // If Cognito returns NotAuthorized, the message typically tells the user that the e-mail
      //   or password is incorrect. In this case, we need to tell them it's the password
      if (e.errorType === COGNITO_ERROR_CODE.NOT_AUTHORIZED_EXCEPTION) {
        e.message = 'Current password is incorrect.';
      }
      return e;
    }
  };

export const logout = (global = false): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    try {
      const headers = await makeHeaders(true, true);
      const body = JSON.stringify({global});
      const revokeTokensPromise = fetchData(API_URLS.REVOKE_TOKENS, { headers, method: 'POST', body });
      const logoutRedirectUri = getState().organization.logout_redirect_uri;

      const amplifySignOutParams: SignOutInput = {global};
      if (logoutRedirectUri) {
        amplifySignOutParams['oauth'] = {redirectUrl: logoutRedirectUri};
      }

      try {
        const revokeTokensResponse = await revokeTokensPromise;
        const revokeTokensResponseStatus = revokeTokensResponse.status;
        if (revokeTokensResponseStatus !== 200) {
          throw Error(`Revoke Tokens Failed: Response Code ${revokeTokensResponseStatus}`);
        }
      } catch (e) {
        cabCaptureException(e);
      }

      await removeLocalStorageOnboarding();
      await amplifySignOut(amplifySignOutParams);

      dispatch({type: ActionType.LOGOUT_SUCCESSFUL});
      
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

export const updateUserPrefs = (
  newUserPrefs: UserPrefs
): ThunkResult<Promise<void>> => 
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({...newUserPrefs});
    const url = API_URLS.USER_PREFS; 
    const actionType = ActionType.UPDATED_USER_PREFS;

    return fetchData(url + '0/', { headers, method: 'PATCH', body }).then((res: FetchReturn): void => {
      if (res.status === 200) {
        dispatch({ type: actionType, userPrefs: res.data });
      }
    }); 
  };

export const saveDeviceToken = (
  registrationId: string, name: string, deviceId: string, type: string
): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    const body = JSON.stringify({
      registration_id: registrationId,
      name,
      device_id: deviceId,
      type,
    });

    fetchData(API_URLS.SAVE_DEVICE_TOKEN, {headers, body, method: 'POST'});
  };


export const saveOAuthGrant = (
  code: string, provider: OAuthProvider, redirectURI?: string
): ThunkResult<Promise<{ missingScopes: string[] } | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    
    const data: {[key: string]: string | string[] | number} = {
      code,
      provider: provider.id
    };

    // We specifically want to check for additional scopes for Microsoft-only for now.
    if (provider.id === PROVIDER.MICROSOFT.id) {
      const incrementalScopesStr = localStorage.getItem(MICROSOFT_INCREMENTAL_SCOPES_LS_KEY);
      localStorage.removeItem(MICROSOFT_INCREMENTAL_SCOPES_LS_KEY);
      if (incrementalScopesStr) {
        data["additionalScopes"] = incrementalScopesStr.split(";;");
      }
    }

    if (redirectURI) {
      data["redirect_uri"] = redirectURI;
    }

    const body = JSON.stringify(data);

    let showError = '';

    try {
      const res = await fetchData<{ username: string; grant_details: string }, { scopes: string[] }>(
        API_URLS.SAVE_OAUTH_GRANT, {headers, body, method: 'POST'}
      );
      if (res.status === 200) {
        dispatch({ 
          type: ActionType.SAVED_OAUTH_GRANT, 
          provider: provider.name, 
          username: res.data.username,
          grant_details: res.data.grant_details
        });
      } else if (res?.status === 400) {
        const user = getState().auth.user;
        switch (res.data.code) {
          case 'missing_scopes':
            cabCaptureMessage(
              `Missing Scopes Detected on OAuth Login Attempt with ${provider.name} User: (${user?.id})`
            );
            return { missingScopes: res.data.detail.scopes };
        }
      } else {
        cabCaptureMessage(`Received unexpected response: ${JSON.stringify(res)}`);
        showError = 'An error occurred';
      }
    } catch (err) {
      console.error(err);
      showError = 'An error occurred';
    }

    if (showError) {
      dispatch(sendMessage({
        timeout: 4000,
        message: showError,
        autoDismiss: true,
        header: "",
        position: { vertical: "top", horizontal: "center" },
        active: true,
        severity: "error",
      }));
    }
  };


export const federatedLogin = (
  {customProvider, provider, customState}: 
  {customProvider?: string, provider?: AmplifyAuthProvider, customState?: string}
): ThunkResult<Promise<void>> => 
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    if (provider) {
      amplifySignInWithRedirect({provider, customState});
    } else if (customProvider) {
      amplifySignInWithRedirect({provider: {custom: customProvider }, customState});
    }
  };

export const signInWithGoogle = (scope: string): ThunkResult<Promise<undefined | { missingScopes: string[] }>> => (
  dispatch: ThunkDispatchType, getState: () => RootState
) => {
  const signInResponse = new Promise<undefined | { missingScopes: string[] }>((resolve) => {
    const client = initializeGoogleClient(scope, async (r) => {
      if (r.code) {
        dispatch(saveOAuthGrant(r.code, PROVIDER.GOOGLE)).then((res) => {
          trackEvent(EVENT_TYPE.SCHEDULING_OAUTH_GOOGLE);
          if (res) {
            resolve(res);
          }
        });
      }
    });
    client.requestCode();
  });
  return signInResponse;
};

export const setAuthRedirect = (url: string | null): AuthAction => {
  return {type: ActionType.SET_AUTH_REDIRECT, url};
};

export const getMsLoginUrl = (
  state?: string, redirectURI?: string, incrementalScopes?: string[]
): ThunkResult<Promise<string>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<string> => {
    const headers = await makeHeaders(true);

    if (incrementalScopes) {
      localStorage.setItem(MICROSOFT_INCREMENTAL_SCOPES_LS_KEY, incrementalScopes.join(";;"));
    } else {
      localStorage.removeItem(MICROSOFT_INCREMENTAL_SCOPES_LS_KEY);
    }

    const params = makeParams({
      redirect_uri: redirectURI,
      additionalScopes: incrementalScopes
    });

    return fetchData(
      API_URLS.MS_LOGIN_URL + params, {headers, method: 'GET'}
    ).then(res => {
      if (res.status === 200) {
        let url = res.data;
        if (state) {
          url += `&state=${state}`;
        }
        return url;
      }
    });
  };

export const getZoomLoginUrl = (redirectURI?: string): ThunkResult<Promise<string>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<string> => {
    const headers = await makeHeaders(true);
    const params = makeParams({ redirect_uri: redirectURI });

    return fetchData(
      API_URLS.ZOOM_LOGIN_URL + params, {headers, method: 'GET'}
    ).then(res => {
      if (res.status === 200) {
        return res.data;
      }
    });
  };

export const getSalesforceLoginUrl = (redirectURI?: string): ThunkResult<Promise<string>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<string> => {
    const headers = await makeHeaders(true);
    const params = makeParams({ redirect_uri: redirectURI });

    return fetchData(
      API_URLS.SALESFORCE_LOGIN_URL + params, {headers, method: 'GET'}
    ).then(res => {
      if (res.status === 200) {
        return res.data;
      }
    });
  };

export const logoutOAuth = (grant: Grant): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {
    const headers = await makeHeaders(true); 
    const body = JSON.stringify({
      username: grant.username,
      provider: PROVIDER_BY_NAME[grant.provider].id
    });

    return fetchData(API_URLS.LOGOUT_OAUTH, {headers, body, method: 'POST'}).then(res => {
      dispatch({ type: ActionType.LOGGED_OUT_OAUTH, provider: grant.provider, username: grant.username });
      if (res.status === 200) {
        return res.data;
      }
    });
  };

export const getZoomSchedulers = (grantId?: number): ThunkResult<Promise<ZoomUserSchedulersSearchResult>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    let url = API_URLS.ZOOM_SCHEDULERS;
    if (grantId) {
      const params = makeParams({ oauth_token_id: grantId });
      url += params;
    }

    try {
      const res = await fetchData(url, { headers, method: 'GET', });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('Problem getting Zoom schedulers');
      }
    } catch (err) {
      return err;
    }
  };

export const getZoomContacts = (grant: Grant, searchStr: string): ThunkResult<Promise<ZoomUserContactsSearchResult>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);
    const url = `${API_URLS.ZOOM_CONTACTS}?search_key=${searchStr}`;

    try {
      const res = await fetchData(url, { headers, method: 'GET' });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('Problem getting Zoom contacts');
      }
    } catch (err) {
      return err;
    }
  };

const updateProfileData = (
  userData: Partial<Pick<User, 'first_name'|'last_name'>>
): ThunkResult<Promise<void>> => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
  const headers = await makeHeaders(true);

  const userId = getState().auth.user?.id;
  if (!userId) throw new Error('No user ID found');

  const body = JSON.stringify(userData);
  
  const res = await fetchData<Pick<User, 'first_name'|'last_name'|'profile'>>(
    API_URLS.PROFILE + `${userId}/`, { headers, method: "PATCH", body }
  );
  if (res.status === 200) {
    const { first_name, last_name, profile } = res.data;
    dispatch({ type: ActionType.UPDATED_USER_PROFILE, userData: { first_name, last_name }, profile });
  } else {
    throw new Error('Could not update profile');
  }
};

interface UploadedProfilePic {pic_url: string};

export const uploadProfileImage = async (file: File) => {
  if (!file) {
    return null;
  }

  const headers = await makeHeaders(true);
  if (headers) {
    delete headers["Content-Type"];
  }

  const formData = new FormData();
  formData.set("pic_url", file);

  const res = await fetchData<UploadedProfilePic>(
    API_URLS.PROFILE_UPLOAD_IMG, { headers, method: "POST", body: formData }
  );
  if (res.status === 200) {
    return res.data;
  } else {
    throw new Error('Could not upload user profile image');
  }
};

export const updateProfile = (
  userData: Partial<Pick<User, 'first_name'|'last_name'>>, file?: File
): ThunkResult<Promise<void>> => async (dispatch: ThunkDispatchType, getState: () => RootState) => {
  if (file) {
    await uploadProfileImage(file);
  }
  return dispatch(updateProfileData(userData));
};

export const acceptTermsOfService = (version: string): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType) => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify({ version });

    const res = await fetchData(
      API_URLS.USER_ACCEPT_TERMS_OF_SERVICE, { headers, body, method: 'POST' }
    );
    if (res.status === 200) {
      dispatch({ type: ActionType.ACCEPTED_TOS });
    } else {
      console.error('Something went wrong accepting ToS');
    }
  };

export const startAmplifyChangeEmail = (email: string): ThunkResult<Promise<UpdateUserAttributeOutput>> => 
  async (dispatch: ThunkDispatchType) => {
    return await amplifyUpdateUserAttribute({
      userAttribute: { 
        attributeKey: "email",
        value: email
      },
    });
  };

export const changeCabinetEmail = (
  newEmail: string
): ThunkResult<Promise<FetchReturn<{detail: {changed_email: string}}>>> => async (
  dispatch: ThunkDispatchType, getState: () => RootState
) => {
  const res = await api.changeEmail(newEmail);
  const user = getState().auth.user;
  if (res.status === 200 && res.data["detail"]["changed_email"] !== user?.email) {
    const updatedEmail = res.data["detail"]["changed_email"];

    dispatch({ 
      type: ActionType.USER_LOADED, 
      user: {
        ...user,
        email: updatedEmail,
        showOnboarding: false
      }});
  }
  return res;
};

export const verifyCognitoUserAttributeChange = (
  attr: ConfirmUserAttributeInput['userAttributeKey'], code: string
): ThunkResult<Promise<CabinetAuthErrorCanRequestCode | void>> => async (
  dispatch: ThunkDispatchType
) => {
  return await amplifyConfirmUserAttribute({ userAttributeKey: attr, confirmationCode: code })
    .catch((err: Error) => {
      let errorType = COGNITO_ERROR_CODE.UNKOWN_EXCEPTION;
      let message = "";
      let canRequestNewCode = true;
      const {
        errorType: cogErrorType,
        message: cogMessage,
        canRequestNewCode: cogCanRequestNewCode
      } = cognitoCodeHandler(err);
      errorType = cogErrorType;
      message = cogMessage;
      canRequestNewCode = cogCanRequestNewCode;
      return { errorType, message, canRequestNewCode };
    });
};

export const getUserAttributes = (
): ThunkResult<Promise<FetchUserAttributesOutput>> => async (dispatch: ThunkDispatchType) => {
  return await amplifyFetchUserAttributes();
};

export const setIsOnboardingInitialized = (isOnboardingInitialized: boolean) => {
  return {type: ActionType.ONBOARDING_INITIALIZED, isOnboardingInitialized};
};
