import React, { Children, ReactElement, ReactNode, useEffect, useState } from "react";
import {  Box, Tabs, Tab, TabProps, TabsProps, lighten, styled, SxProps } from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";


interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

export interface CabTabsProps extends CabComponentProps<TabsProps> {
  disabled?: boolean;
  children?: TabsProps['children'];
  variant?: TabsProps['variant'];
  centered?: TabsProps['centered'];
  onChangeTab?: (tabIdx: number) => void;
  initValue?: number;
  isSharedChild?: boolean;
  tabs: {
    onClick?: TabProps['onClick'];
    hidden?: TabProps['hidden'];
    label?: TabProps['label'];
    disabled?: TabProps['disabled'];
    id?: TabProps['id'];
  }[];
  align?: "left" | "right";
  hideDivider?: boolean;
  sx?: TabsProps['sx'];
  tabPanelSx?: SxProps;
  id?: TabProps['id'];
}


export const CabTabs = ({
  disabled, tabs, overrides, children, isSharedChild, centered, onChangeTab, variant, initValue, align, hideDivider, sx,
  tabPanelSx, id
}: CabTabsProps): ReactElement => {
  const tabsProps: TabsProps = {
    centered,
    variant,
  };
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (initValue && initValue !== 0) {
      setValue(initValue);
    }
  }, [initValue]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (onChangeTab) onChangeTab(newValue);
  };

  return (
    <Box sx={{ width: '100%', ...sx}}>
      <Box sx={hideDivider ? {} : { borderBottom: 1, borderColor: 'divider' }}>
        <TabsStyled {...tabsProps} {...overrides} value={value} allowScrollButtonsMobile  
          variant={centered ? "fullWidth" : "scrollable"}
          onChange={handleChange} align={align} id={id}
        >
          {tabs.map((tab, index) => (
            <TabStyled 
              key={index} 
              hidden={tab.hidden}
              disabled={tab.disabled || disabled} 
              label={tab.label} 
              onClick={tab.onClick}
              id={tab.id}
            />
          ))}
        </TabsStyled>
      </Box> 
      {Children.map(children, (child, index) => (
        isSharedChild
          ? <TabPanel value={index} index={index} children={children} sx={tabPanelSx} />
          : <TabPanel value={value} index={index} children={child} sx={tabPanelSx} />
      ))}
    </Box>
  );
};

const TabStyled = styled(
  Tab, {label: "TabStyled"}
)<CabComponentProps<TabProps>>(({ theme }) => ({
  textTransform: 'none',
  ...(theme.palette.mode === 'dark' ? {
    color: colors.white600,
    '&.Mui-selected': {
      color: colors.lavendar900,
      '&:hover': {
        color: lighten(colors.lavendar900, .4)
      },
      '&.Mui-disabled': {
        color: lighten(colors.lavendar900, .4)
      }
    },
    '&:hover': {
      color: colors.white700
    },
    '&.Mui-disabled': {
      color: colors.white400
    },
  } : {
    color: colors.black600,
    '&.Mui-selected': {
      color: colors.lavendar900,
      '&:hover': {
        color: colors.lavendar950
      },
      '&.Mui-disabled': {
        color: lighten(colors.lavendar900, .4)
      }
    },
    '&:hover': {
      color: colors.black700
    },
    '&.Mui-disabled': {
      color: colors.black400,
    },
  }),
}));

const TabsStyled = styled(Tabs, {
  label: "TabsStyled", shouldForwardProp: prop => prop !== 'align'
})<CabComponentProps<TabsProps> & {align: CabTabsProps['align']}>(({ theme, align }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: colors.lavendar900,
  },
  ...(align === 'left' && {
    '& .MuiTab-root': {
      paddingLeft: 0,
      paddingRight: 0,
      marginRight: 32
    }
  }),
  ...(align === 'right' && {
    '& .MuiTab-root': {
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: 32
    }
  })
}));
