import { parsePhoneNumberFromString } from 'libphonenumber-js';
import confetti from 'canvas-confetti';

export const formatPhoneNumber = (phoneString: string): string => {
  try {
    // Should be able to handle international phone numbers but
    // defaulting to US makes general performance better
    const tel = parsePhoneNumberFromString(phoneString, 'US');
    if (tel) {
      return tel.format('INTERNATIONAL');
    } else {
      return phoneString;
    }
  } catch (e) {
    return phoneString;
  }
};

const fireConfetti = (
  particleRatio: number, opts: { spread: number, startVelocity?: number, decay?: number, scalar?: number }
) => {
  const count = 200;
  const defaults = {
    origin: { y: 0.7 }
  };
  confetti(Object.assign({}, defaults, opts, {
    particleCount: Math.floor(count * particleRatio)
  }));
};

export const successConfetti = () => {
  fireConfetti(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fireConfetti(0.2, {
    spread: 60,
  });
  fireConfetti(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  });
  fireConfetti(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  });
  fireConfetti(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

export const getIconSrc = (url?: string) => {
  return url && url.split('/').pop()?.startsWith('profiledefault-')
    ? undefined
    : url;
};
