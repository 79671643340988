import { ReactElement, createRef, ChangeEvent } from 'react';
import { Calendar, Leader, OrgUserAutocompleteOption } from '../../../store';
import loadImage from 'blueimp-load-image';
import { trackEvent } from '../../../utils/appAnalyticsUtils';
import { ALLOWED_IMG_EXTS, EVENT_TYPE } from '../../../constants';
import { CabAutocomplete, CabAvatar, CabButton, CabIcon, CabTextInput } from '@CabComponents';
import { FormControl, FormHelperText, FormLabel, Grid, styled } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import colors from "../../../colors";
import { getLeaderTemplate } from '../../../store/templates';
import { CabModal } from '@CabComponents/CabModal';
import { validateEmail } from '../../../utils/inputUtils';
import { useCabinetText } from '../../../CabinetContext';
import { IoAddCircleOutline } from 'react-icons/io5';

export interface NewLeader extends Leader {
  img?: File | null;
  imgName?: string;
  calendars?: number[];
}

interface Props {
  handleSaveLeader: (data: Leader) => void;
  newLeader?: Leader;
  firstLeader: boolean;
  cancelAddLeader: () => void;
  nextDisabled: boolean;
  isOpen: boolean;
  calendars?: Calendar[];
  shareWithUserOptions?: OrgUserAutocompleteOption[];
}

const StyleCabTextInput = styled(CabTextInput, {label: "StyledCabTextInput"})({
  width: "100%",
  marginTop: 12
});

const StyledImageGrid = styled(Grid, {label: "StyledImageGrid"})({
  width: "100%",
  height: 125,
  backgroundColor: colors.white800,
  marginTop: 12,
  marginBottom: 12,
  borderRadius: 4
});

export const LeaderInfo = 
({ handleSaveLeader, newLeader, isOpen, cancelAddLeader, calendars, shareWithUserOptions 
}: Props): ReactElement => { 
  
  const [modalTitle, photoPlaceholder, buttonText] = useCabinetText([
    'add-team-member-title',
    'add-team-member-image',
    'add-team-member-button',
  ]);

  const picInputRef = createRef<HTMLInputElement>();

  const defaultValues: NewLeader = newLeader ? newLeader : getLeaderTemplate();
  defaultValues["img"] = null;
  defaultValues["calendars"] = [];

  const meetingForm = useForm< NewLeader>({ defaultValues });
  const { control, handleSubmit: formSubmit, setValue, watch, formState } = meetingForm;

  const [pic_url, first_name, last_name, primary_assistant] = 
  watch(["pic_url", "first_name", "last_name", "primary_assistant"]);

  const handleSubmit = async (data:  NewLeader) => {
    handleSaveLeader(data);
    trackEvent(EVENT_TYPE.CREATE_EXEC);
    closeModal();
  };
  
  const handleUploadImageClick = (): void => {
    if (picInputRef.current) {
      picInputRef.current.click();
    }
  };

  const closeModal = () => {
    meetingForm.reset();
    cancelAddLeader();
  };

  const handleChangeImage = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target && e.target.files) {
      const orig_img: File = e.target.files[0];

      loadImage(orig_img, (canvas): void => {
        if (canvas instanceof HTMLCanvasElement) {
          const url = canvas.toDataURL('image/png');
          setValue("imgName", orig_img.name);
          setValue("pic_url", url);
          setValue("img", orig_img);
        }
      }, {canvas: true, orientation: true, maxWidth: 200, maxHeight: 200, crop: true});
    }
  };
  
  const renderImageUpload = (): ReactElement => {
    const imageInput = <input type="file" id="file" ref={picInputRef} 
      style={{ display: "none" }} onChange={handleChangeImage} accept={ALLOWED_IMG_EXTS.join(",")}/>;
    if (pic_url) {
      return (
        <>
          <CabAvatar
            sx={{cursor: "pointer"}}
            color={defaultValues.color}
            src={pic_url}
            size="intermediate"
            name={`${first_name} ${last_name}`}
            onClick={handleUploadImageClick}
          />
          {imageInput}
        </>
      );
    } else {
      return (
        <CabButton
          buttonType='text'
          sx={{
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent !important"
            }
          }}
          size="large"
          onClick={handleUploadImageClick}>
          <CabIcon size='large' Icon={IoAddCircleOutline}/>
          {imageInput}
        </CabButton>
      );
    }
  };

  return (
    <CabModal 
      open={isOpen} 
      onClose={closeModal} 
      title={modalTitle} 
      closeIcon 
      closeOnBackdropClick
      actionButtons={
        <CabButton
          onClick={formSubmit(handleSubmit)} 
          disabled={!formState.isValid || !(!shareWithUserOptions || (primary_assistant && primary_assistant > 0))}
        >
          {buttonText}
        </CabButton>
      }
    >
      <form id='leader-info-form'>
        <Controller name="first_name" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <>
              <StyleCabTextInput
                {...field}
                inputRef={ref}
                label="First Name"
                required
              />
              <FormHelperText error>{error && 'First name is required'}</FormHelperText>
            </>
          )} />
        <Controller name="last_name" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <>
              <StyleCabTextInput
                {...field}
                inputRef={ref}
                label="Last Name"
                required
              />
              <FormHelperText error>{error && 'Last name is required'}</FormHelperText>
            </>
          )} />
        <Controller name="email" control={control} rules={{ required: true, validate: validateEmail }}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <>
              <StyleCabTextInput
                {...field}
                inputRef={ref}
                type="email"
                label="Email"
                required
              />
              <FormHelperText error>
                {error?.type === 'required' ? 'Email is required'
                  : error?.type === 'validate' ? 'Must be a valid email'
                    : ''}
              </FormHelperText>
            </>
          )} />
        {calendars && (
          <FormControl fullWidth sx={{marginTop: 1}}>
            <FormLabel>Attach Calendar(s)</FormLabel>
            <Controller name="calendars" control={control} render={({ field: { ref, ...field } }) => (
              <CabAutocomplete<number, never>
                {...field}
                placeholder="Select a calendar"
                multiple
                sx={{ width: 'auto' }}
                options={calendars.map((calendar) => ({
                  value: calendar.id,
                  label: calendar.additionalCalendarEmail ? calendar.additionalCalendarEmail.name : calendar.summary,
                  color: calendar.backgroundColor
                }))}
              />
            )} />
          </FormControl>
        )}
        {shareWithUserOptions && (
          <FormControl fullWidth sx={{marginTop: 1}}>
            <FormLabel>Primary Support*</FormLabel>
            <Controller name="primary_assistant" control={control}
              render={({ field: { ref, ...field } }) => (
                <CabAutocomplete<number, never>
                  {...field}
                  placeholder={'Find teammates by name or email'}
                  options={shareWithUserOptions}
                />
              )} />
          </FormControl>
        )}
        <StyledImageGrid
          container
          display="flex"
          direction="column"
          alignItems={"center"}
          justifyContent="center"
        >
          {renderImageUpload()}
          {defaultValues.id === -1 ? photoPlaceholder : 
            'Click to change photo'}
        </StyledImageGrid>
      </form>
    </CabModal>
  );
};

export default LeaderInfo;
