import { createTheme } from '@mui/material/styles';
import colors from './colors';


export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: colors.lavendar950,
      light: colors.lavendar700,
      contrastText: colors.systemWhite,
    },
    secondary: {
      main: colors.lavendar500,
      // light: colors.lavendar300,
      contrastText: colors.ink,
    },
    error: {
      main: colors.sunset950,
    },
    accent: {
      main: colors.navyPrimary,
      light: colors.navyLight,
      dark: colors.navyDark,
      contrastText: colors.systemWhite,
    }
  },
  typography: {
    fontFamily: 'Satoshi',
    caption: {
      fontSize: 10,
      lineHeight: '12px',
      color: colors.ink,
    },
    body1: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '22px',
      color: colors.ink,
    },
    body2: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '22px',
      color: colors.ink,
    },
    h1: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '31px',
      color: colors.ink,
    },
    h2: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '22px',
      color: colors.ink,
    },
    h3: {
      fontWeight: 700,
      fontSize: 22,
      lineHeight: '22px',
      color: colors.ink,
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      color: colors.ink,
    },
    h5: {
      fontWeight: 500,
      fontSize: 18,
      color: colors.ink,
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
      textTransform: 'uppercase',
      color: colors.ink,
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '22px',
      color: colors.ink,
    },
    button: {
      fontWeight: 500,
      fontSize: 16, 
      lineHeight: '20px',
      textTransform: 'initial',
      boxShadow: 'none',
    },
  },
}, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          ":hover": {
            boxShadow: 'none',
          }
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          fontWeight: 400,
          fontSize: 13,
          lineHeight: '22px',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          color: colors.black900,
          fontSize: 15,
          lineHeight: '23px',
          '&.MuiFormLabel-colorPrimary': { color: colors.black900 },
          '&.MuiFormLabel-colorPrimary .Mui-disabled': { color: colors.black400 },
        }
      }
    },
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.white900,
    },
    secondary: {
      main: colors.lavendar500,
      // main: chroma(colors.black900).brighten(0.6).hex(),
      // contrastText: colors.white900,
    },
    error: {
      main: colors.sunset950,
    },
    accent: {
      main: colors.greenLight,
      light: colors.greenPrimary,
      contrastText: colors.navyDark,
    },
  },
  typography: theme.typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          ":hover": {
            boxShadow: 'none',
          }
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.white900
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          fontWeight: 400,
          fontSize: 13,
          lineHeight: '22px',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          fontSize: 15,
          lineHeight: '23px',
          color: colors.white900,
          '&.MuiFormLabel-colorPrimary': { color: colors.white900 },
          '&.MuiFormLabel-colorPrimary .Mui-disabled': { color: colors.white400 },
        }
      }
    },
  }
});

export default theme;

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary'];
  }
  interface PaletteColor {
    accent?: string;
  }
  interface SimplePaletteColorOptions {
    accent?: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accent: true;
  }
}

